// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$primaryColor: #963A46;
$grey: #7b7b7b;
$menu_admin_color: rgba(26, 35, 47, 0.91);

$primaryFont: Miller-Display, serif;

/*1  GENERAL
----------------------*/
body{
  background-color: $primaryColor;
}
p{
  color: $grey;
}
@font-face {
  font-family:"Miller-Display";
  src: url("/assets/fonts/Miller-Display.ttf");
}

/* 2 PÁGINA DE INICIO
----------------------*/
.shipping{
  color: white;
  padding: 1em;
  z-index: 0;
  font-size: 1.3em;
  a{
    color: lighten( $primaryColor, 30% );
    &:hover{
      color:white;
    }
  }
}

.lateral-bar{
  padding-top: 2em;

  .shipment-info-lateral{
    background-color: $primaryColor;
    padding: 10px;
    color: white!important;
    p {
      color:white!important;
    }
    margin-top:1em;
  }
  p {
    margin-top:1em;
  }
  .big_letters {
    font-size: 4em;
  }
  .fa-facebook-official{
    margin-top: 1.2em;
  }
  .iva-text{
    margin-top:3em;
    padding:1em;
    background-color:$primaryColor ;
    color:white;
  }
  .img-facebook{
    margin-top: 1em;
    border: 4px solid $primaryColor;
  }


  .fa-truck{
    color: white;
  }
  a{
    color: white;
    &:hover{
      color: white;
    }
  }
}

// 2.1 General
header .head{
  padding: 3em;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 0 15px -3px #7b7b7b;
  z-index: 10;
}
.intro{
  padding: 3em 3em 1.5em;
  h2{
    color:$primaryColor;
  }
  .text{
    padding-top: 1em;
  }
}
.content{
  background-color: #eff1f0;
  z-index: 0;
  padding: 1.5em 5em 1.5em;
  box-shadow: 0 0 15px -3px #7b7b7b;

  .row>.col-md-3{
    padding-left: 1.5em;
    padding-right:1.5em;
  }
}
.featured{
  z-index: 1;
  padding-bottom: 5em;
}

// 2.2 Otros productos
.parallax{
  margin: -1.5em;
  height: 300px;
  background-size: cover;
  background-attachment: fixed;
  background-position-x: 50%;
  background-position-y: 0;
  background-color: #1a1117;
  background-image: url('/assets/img/wineyard.jpg');
}
@media (min-width: 978px){
  .parallax {
    margin-left: -5em;
    margin-right: -5em;
  }
}
.otherProducts {
  margin-top: 1.5em;
  h3{
    margin-bottom: 1em;
  }
}
@media (max-width: 781px) {
  .navbar-header{
    margin-bottom:2em;
  }
}
@media (min-device-width: 1500px) {
  .otherProducts{
    margin-left: 15% !important;
    margin-right: 15% !important;
  }
}
.otherTitle {
  height: auto;
  background-size: cover;
  background: black url('/assets/img/other.jpg') no-repeat;
  background-position-x: 0 !important;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: white !important;
  h3 {
    margin-bottom: 1em;
  }
  p {
    color: white;
  }
}

.lastRow{
  margin-top:0;
}

// 2.3 Caracteristicas
.other{
  margin-top:0;
  background-color: white;
  padding-bottom: 4em;
}
.whyUs i{
  padding: 0.5em;
}



/* 3 TARJETA DE PRODUCTO
----------------------*/
.product {
  display: block;
  margin-top: 1.5em;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0 0 15px -3px #7b7b7b;
  h3 {
    font-family: $primaryFont;
    font-size: 1.5em;
    color: black !important;
    margin-top: 0.5em;
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;

    /* Para centrado vertical*/
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4em;

  }
  .separator {
    margin-top: 1em;
    font-family: $primaryFont;
    color: $primaryColor;
  }
  img {
    width: 100%;
    height: auto;
  }
  .excerp {
    padding: 0.5em 1em;
    height: 4em;
  }
  .options {
    padding: 0.5em 1em;

    font-family: $primaryFont;
    font-size: 1.2em;
    color: #7b7b7b;
  }
  .options {
    .buttons {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .buttons > a {
      color: $primaryColor;
      margin-left: 1em;
    }
  }
  .type {
    font-family: Arial, sans-serif;
    color: #7b7b7b;
    margin-top: 0.8em;
    letter-spacing: 0.3em;
    font-size: 0.9em;
  }
  .price_card{
    font-family:$primaryFont;
    color: $primaryColor
  }
}
@media (max-width: 763px){
  .product{
    h3{
      /* Para centrado vertical*/
      display: block;
      font-size: 2em!important;
      height: auto;
    }
  }
}

.content {
  h3 {
    padding-left: 0.2em;
    padding-right: 0.2em;
    color: #7b7b7b;
  }
  h4 {
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #7b7b7b;
  }
  p {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

/* 4 NAVBAR*/
.navbar-brand {
  height: 80px!important;
}

.navbar {
  margin-left: 1em;
  font-family: Arial, serif;
  background: none;
  border: none;
  margin-top: 1em;
}
.navbar-nav{
  .active > a{
    $border-width: 5px;
    border: 0 solid $primaryColor;
    border-top-width: $border-width;
    padding-top: 15 - $border-width;
    background: none!important;
  }
  .open > a {
    background: none !important;
  }
}
.navbar-brand {
  padding: 5px;
  padding-left: 1.5em;

  & > img {
    height: 100%;
  }
}
.search a:hover > span {
  color: $primaryColor;
}
.active_basket a {
  color: $primaryColor !important;
}
.basket a:hover {
  color: $primaryColor !important;

}
.fb{
  a i{
    font-size: 1.3em;
  }
}
//Cambio del breakpoint de collapse
@media (max-width: 1200px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

@media (min-width: 768px) and (max-width: 992px){
  .wine-title{
    height: 60px;
  }
  .vertical-center{
    -webkit-box-align : center;
    -webkit-align-items : center;
    -moz-box-align : center;
    -ms-flex-align : center;
    align-items : center;
  }
}
@media (max-width: 978px) {
  .content{
    padding: 1.5em 1.5em 1.5em;
    .row>.col-md-3{
      padding-left: 1em;
      padding-right:1em;
    }
    h3 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .intro{
    padding: 1.5em 1.5em 1.5em;
  }
  //NAVBAR PARA MOVILES
  .navbar-nav{
    li>a {
      padding-top: 10px;
    }
    .active>a{
      color: $primaryColor!important;
      border: 0;
      border-top-width: 5px;
      padding-top: 5px;
      background: none!important;
    }
  }
  //DASHBOARD PARA MOVILES
  .dashboard-row{
    margin-left: 0!important;
    margin-right: 0!important;
  }
}


/* 5 LOGIN
----------------------*/
.login{
  background: white;
  padding-top: 4em;
}
.loginBox {
  input {
    width: 100%;
  }
  p {
    margin-top: 2em;
  }
  img {
    width: 40%;
    height: auto;
    margin-bottom: 2em;
  }
  .alert {
    margin-top: 2em;
  }
}

/* 6 ADMIN INTERFACE
----------------------*/

// 6.1 General
.admin{
  background: #F0F4F5;
}
.admin-bar{
  background: $menu_admin_color;
  padding: 1em;
  color: white;
  font-size: 2em;
}
.admin-content {
  background: #F0F4F5;
  .select_page{
    margin-bottom: 2em;
  }
  .title {
    color: $menu_admin_color;
    font-size: 2em;
    box-shadow: 0 -5px 20px 0px #888888;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0.5em 1em;
    background: white;
    margin-bottom: 2em;
  }
}
.dashboard-row{
  margin-left: 2em;
  margin-right: 2em;

}
.panel-dashboard {
  background-color: white;
  box-shadow: 0px 0px 15px 0px #888888;
  padding: 1em;
  margin-bottom: 1em;

  h3 {
    margin-top: 0;
  }
  td i {
    margin-left: 1em;
  }
}

// 6.2 Menú
.menu {

  color: white;
  background: #2F3A4C;
  box-shadow: 5px 0 5px -7px #888888;
  min-height: 100vh;

  .brand{
    color: white;
    margin-left: -15px;
    margin-right: -15px;

    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 2em;
    box-shadow: 0 -5px 20px 0 black;

  }
  .entry {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0.5em;
    padding-left: 1.3em;
    font-size: 1.3em;
  }
  .active {
    background-color: $menu_admin_color;
    border: 0 solid white;
    border-left-width: 0.3em;
    padding-left: 1em;
  }
  a {
    color: inherit;
    &:hover {
      font-style: inherit;
    }
  }
}
.entry > i{
  margin-right: 0.5em;
  margin-bottom:0.2em;
}

.without-options{
  min-height: 0!important;
}

//6.3 Añadir-editar vino
.preview input {
  background-color: transparent;
  border: none;
  width: 100%;
  textarea.form-control {
    height: 10em;
    box-sizing: border-box;
    resize: none;
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  p {
    width: 100%;
    height: 100%;
  }
}
.send{
  margin:3em;
}
.success{
  background-color: white!important;
  padding-top: 4em!important;
}
.success-text{
  margin-top:3em;
}
.years .input-group{
  margin-bottom: 1em !important;
}



.productName{
  position:absolute;
  top:45%;
  width: 86%;
  text-align: center;
}


/* 7 Página de productos
------------------------*/
#filter{
  padding: 1.5em;
}
#wines{
  padding-top: 1.5em;
  .form-inline{
    margin-bottom: 1em;
  }
  .input-group {
    max-width: 20em;
    width: 100%;

  }
}
.wine_container{
  padding: 0!important;
}
.wine_listing{
  padding: 3em 1em 1em;
  margin-left: 0;
  margin-right: 0;
}

.wine_item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}


.wine_item {
  position: relative;
  margin-bottom: 2em;
  padding: 0;
  box-shadow: 0 0 15px -3px #7b7b7b;
  background-color: white;
  h3 {
    font-family: $primaryFont;
    font-size: 1.35em;
    color: black !important;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  p{
    margin-bottom: 0;
    font-weight: bold;
  }
  .wine_img{
    max-height: 8.4em;
    width: auto;
    height: auto;
    max-width:28%;
    padding: 0;
    z-index: 2;
    float: left;
    position: relative;
    img{
      max-height: 8.4em;
    }
  }
  .wine_description{
    padding-bottom: 1.5em;
    padding-left: 2.3em;
  }
  .region_listing{
    color: $primaryColor;
    padding-left: 0.5em;
  }
  .price_listing{
    display:none;
    color: $primaryColor;
    font-size: 1.2em;
    font-family: $primaryFont;
    margin-top: 12px;
    margin-left:1em;
  }
  .ribbon_options{
    box-shadow: inset 0 15px 20px -20px darken($primaryColor,20%);
    margin-top:-32px;
    width: 100%;
    background-color: $primaryColor;
    z-index: 0;
    color: white;
    padding-left: 130px;
    padding-right: 1.5em;
    height: auto;
    font-size:1em;
    padding-top: 5px;

    a{
      color: white;
      &:hover{
        color: white;
      }
    }

    .ribbon_text{
      display: inline-block;
      vertical-align: middle;
      font-size: 0.8em;
      margin-left: 0.2em;
      margin-right: 0.8em;
    }
  }
  .small_price{
    color: white;
    font-size: 1.2em;
    font-family: $primaryFont;
    margin-left: 1em;
  }
}

// Para moviles muy pequeños
@media (max-width: 596px){
  .wine_item{
    margin-left: 3em;
    margin-right: 3em;
  }
  .wine_img{
    max-height: 100% !important;
    height: 100%;
    padding-top: 0!important;
    padding-bottom: 0!important;
    max-width: 100% !important;
    float: none!important;
    width: 100%!important;
    box-shadow: 0 0 15px -3px #7b7b7b;
    img {
      max-height: 100% !important;
      max-width: 100%;
      float: none;
    }
  }
  .ribbon_options{
    padding-left: 0!important;
    font-size:1em!important;
  }
  .wine_name{
    font-size: 1.6em!important;
  }
  .price_listing{
    display: none;
  }
  .small_price{
    display: block!important;
  }
  .wine_description{
    padding-bottom:3.5em!important;
    font-size: 0.8em!important;
  }
}

// Tamaños de tipografia para dispositivos pequeños
@media(max-device-width: 669px){
  .wine_item{
    .price_listing{
      font-size: 1.2em;
    }
    .ribbon_options{
      font-size: 1.3em;
    }
  }
}

/* 8 VINO - PAGINA
----------------------*/

// 8.1 GENERAL
.products-header{
  padding-bottom: 1.5em!important;

}
.product-info{
  margin-top: 3em;
  margin-bottom: 2em;
  background-color: #eff1f0;
  box-shadow: 0px 0px 20px -3px #474747;
  h2{
    font-size: 2em;
  }

}
@media(max-device-width: 928px){
  .product-info{
    h2{
      font-size: 1.5em!important;
    }
  }
}
.product-actions {
  margin-bottom: 2em;
  .price {
    font-size: 2em;
    color: white;
    font-family: $primaryFont;
  }
  i {
    margin-top: 1em;
    color: white;
  }
}

@media (max-device-width: 1440px){
  .price{
    font-size: 1.5em !important;
  }
}
@media (max-width: 768px) {
  .product-actions .price {
    font-size: 1.5em;
  }
  .product-actions i{
    margin-top: 0.7em;
  }
}
@media (max-width: 430px) {
  .product-actions .price {
    font-size: 1em!important;
  }
  .product-actions i{
    margin-top: 0!important;
  }
}

// 8.2 Foto y descripcion
.photo {
  padding-left: 0;
  padding-right: 0;
  img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .photo{
    padding-top: 3.5em;
    padding-bottom: 3.5em;
  }
}

.description {
  z-index: 0;
  padding: 3em 5em 1.5em;

  h2{
    margin-top: 0;
    font-family: $primaryFont;

  }
}
.short_desc{
  font-weight: bold;
}
@media (max-width: 1390px){
  .description{
    z-index: 0;
    padding: 1.5em 1.5em 1.5em;
  }
}

// 8.3 Opciones
.buy-buttom{
  position: absolute;
  bottom: 4.5em;
  right: 4em;
}
.price-tag{
  position: absolute;
  bottom: 2.45em;
  right: 14em;
  font-size: 1.5em;
  font-family: $primaryFont;
}


// 8.4 Notas de cata
.notes{
  background-size: cover;
  background: black url('/assets/img/notes2.jpg') no-repeat fixed;
  background-position-x: 0!important;
  color:white!important;
  padding: 3em;
  p {
    color: white;
    font-size: 1.2em;
  }
  span {
    display: inline-block !important;
    vertical-align: middle !important;
  }
}

// 8.5 Región
.region-description{
  background: white;
  padding: 3em;
  h2 {
    font-family: $primaryFont;
  }
}
.region {
  box-shadow: 0 0 15px -3px #7b7b7b;
  background: white;
}

//8.6 Relacionados
.related{
  background-color: white;
  box-shadow: 0 0 15px -3px #7b7b7b;
  padding-top: 1em;
  .section_title{
    font-family: $primaryFont;
    margin-bottom: 1em;
  }
}
.region_section{
  position: relative;
}
.region_info{
  padding-top: 1em;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.4);
  p{
    padding: 2em;
    color: white;
  }
  h3{
    font-size: 2.5em;
    color: white;
    font-family: $primaryFont;
  }
}


.description_wine p{
  margin-top: 0;
  margin-bottom: 0;
}

/* 9 BASKET
----------------*/
.basket_table{
  background-color: white;
  margin-top: 3em;
  padding: 2em 3em;

  .address{
    margin-bottom: 0.2em;
  }

  .primary {
    color: $primaryColor !important;
  }
  .total{
    font-size: 1.5em;
  }

  h2 {
    margin-bottom: 1em;
  }
  .fa{
    color: $grey;
    padding-top: 3px;
    &:hover{
      color: $primaryColor;
    }
  }
}
.nothing{
  color: white;
  margin-top: 15%;
}
.basket_actions{
  font-size: 1.7em;

  button {
    margin-left: 1em;
  }
}

.address_choice{
  margin-top:1em;
  margin-bottom: 1em;
}


/* 10 SLIDER
----------------*/
.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}
.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
}
.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}
.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
}



/* 11 FOOTER
--------------*/
.footer{
  padding-top: 3em;
  color: lighten($grey,20%);

}

/* 12 US
--------------*/
.us-body{
  background-size: cover;
  background: black url('/assets/img/notes2.jpg') no-repeat fixed;
  background-position-x: 0!important;
  color:white!important;
  min-height: 10em;
  padding: 4em;
  img{
    width: 30%;
    margin-bottom: 2em;
  }
  p{
    color: white;
    font-size: 1.5em;
  }
}

@media (max-device-width: 500px) {
  .us-body{
    p{
      font-size: 1em;
    }
  }
}
@media (max-device-width: 400px) {
  .us-body{
    padding: 2em;
  }

}
.contact-location{
  padding-top: 2em;
}
.map_container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Ratio 16:9 ( 100%/16*9 = 56.25% ) */
}
.map_container .map_canvas{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  iframe{
    width: 100%;
    height: 100%;
  }
}


//TODO aplicar esto
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}
.reset_margin_top > i{
  margin-top: 0 !important;
}


/* COL-MS */
.col-ms-1,
.col-ms-2,
.col-ms-3,
.col-ms-4,
.col-ms-5,
.col-ms-6,
.col-ms-7,
.col-ms-8,
.col-ms-9,
.col-ms-10,
.col-ms-11,
.col-ms-12 {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  (30 / 2);
  padding-right: (30 / 2);
}

// ----- col-ms breakpoints and related classes -----
@media (min-width: 480px) {
  .container {
    @media (max-width: 768px ) {
      max-width: 768 - 20px;
    }
  }

  .col-ms-1,
  .col-ms-2,
  .col-ms-3,
  .col-ms-4,
  .col-ms-5,
  .col-ms-6,
  .col-ms-7,
  .col-ms-8,
  .col-ms-9,
  .col-ms-10,
  .col-ms-11 {
    float: left;
  }
  .col-ms-1  { width: percentage((1 / 12)); }
  .col-ms-2  { width: percentage((2 / 12)); }
  .col-ms-3  { width: percentage((3 / 12)); }
  .col-ms-4  { width: percentage((4 / 12)); }
  .col-ms-5  { width: percentage((5 / 12)); }
  .col-ms-6  { width: percentage((6 / 12)); }
  .col-ms-7  { width: percentage((7 / 12)); }
  .col-ms-8  { width: percentage((8 / 12)); }
  .col-ms-9  { width: percentage((9 / 12)); }
  .col-ms-10 { width: percentage((10/ 12)); }
  .col-ms-11 { width: percentage((11/ 12)); }
  .col-ms-12 { width: 100%; }

  // Push and pull columns for source order changes
  .col-ms-push-1  { left: percentage((1 / 12)); }
  .col-ms-push-2  { left: percentage((2 / 12)); }
  .col-ms-push-3  { left: percentage((3 / 12)); }
  .col-ms-push-4  { left: percentage((4 / 12)); }
  .col-ms-push-5  { left: percentage((5 / 12)); }
  .col-ms-push-6  { left: percentage((6 / 12)); }
  .col-ms-push-7  { left: percentage((7 / 12)); }
  .col-ms-push-8  { left: percentage((8 / 12)); }
  .col-ms-push-9  { left: percentage((9 / 12)); }
  .col-ms-push-10 { left: percentage((10/ 12)); }
  .col-ms-push-11 { left: percentage((11/ 12)); }

  .col-ms-pull-1  { right: percentage((1 / 12)); }
  .col-ms-pull-2  { right: percentage((2 / 12)); }
  .col-ms-pull-3  { right: percentage((3 / 12)); }
  .col-ms-pull-4  { right: percentage((4 / 12)); }
  .col-ms-pull-5  { right: percentage((5 / 12)); }
  .col-ms-pull-6  { right: percentage((6 / 12)); }
  .col-ms-pull-7  { right: percentage((7 / 12)); }
  .col-ms-pull-8  { right: percentage((8 / 12)); }
  .col-ms-pull-9  { right: percentage((9 / 12)); }
  .col-ms-pull-10 { right: percentage((10/ 12)); }
  .col-ms-pull-11 { right: percentage((11/ 12)); }

  // Offsets
  .col-ms-offset-1  { margin-left: percentage((1 / 12)); }
  .col-ms-offset-2  { margin-left: percentage((2 / 12)); }
  .col-ms-offset-3  { margin-left: percentage((3 / 12)); }
  .col-ms-offset-4  { margin-left: percentage((4 / 12)); }
  .col-ms-offset-5  { margin-left: percentage((5 / 12)); }
  .col-ms-offset-6  { margin-left: percentage((6 / 12)); }
  .col-ms-offset-7  { margin-left: percentage((7 / 12)); }
  .col-ms-offset-8  { margin-left: percentage((8 / 12)); }
  .col-ms-offset-9  { margin-left: percentage((9 / 12)); }
  .col-ms-offset-10 { margin-left: percentage((10/ 12)); }
  .col-ms-offset-11 { margin-left: percentage((11/ 12)); }
}


.footer-vidi{
  padding: 1em;
  padding-top: 3em;
  color:white!important;
  p{
    color: white!important;
  }
  a{
    color: white!important;
    &:hover{
      color: white!important;
    }
  }
  .fa{
    color: white!important;
  }
}


// CK EDITOR
.description{
  p{
    margin-top:0!important;
    margin-bottom: 0!important;
  }
}